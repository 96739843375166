import React, { useState } from "react"
import { Container, Form, Col, Row, Button, Alert } from "react-bootstrap"
import api from "../utils/api"
import { useQueryParam, StringParam } from "use-query-params"
import { Link, navigate } from "gatsby"
import LoadingOverlay from "react-loading-overlay"
import "bootstrap/dist/css/bootstrap.min.css"

export default function Reset() {
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [userId, setUserId] = useQueryParam("user", StringParam)
  const [token, setToken] = useQueryParam("token", StringParam)

  const resetPassword = async () => {
    setError("")
    setSuccess("")
    if (!password || !confirm) {
      return setError("All fields are required")
    }
    if (password.length < 6) {
      return setError("Password has to be at least 6 characters long")
    }
    if (
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      return setError(
        "Password must contain an uppercase letter a lower case letter and a number"
      )
    }
    if (password !== confirm) {
      return setError("Password and confirm do not match")
    } else {
      try {
        setLoading(true)
        await api().post(`/users/password-reset/${userId}/${token}`, {
          password,
          confirm,
        })
        setSuccess(`Password has been reset`)
        setLoading(false)
        navigate("/login")
      } catch (error) {
        const message = error.response.data.message
        setLoading(false)
        setError(message)
      }
    }
  }
  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }} style={{ marginTop: "100px" }}>
          <h1 className="text-center">Reset your password</h1>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <Form>
            <Form.Group>
              <Form.Control
                className="mb-2"
                type="password"
                placeholder="Enter your new password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Form.Control
                type="password"
                placeholder="Confirm your new password"
                value={confirm}
                onChange={e => setConfirm(e.target.value)}
              />
            </Form.Group>
            <LoadingOverlay active={loading} spinner>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                type="button"
                onClick={resetPassword}
                disabled={!password || !confirm}
              >
                Reset
              </Button>
            </LoadingOverlay>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
